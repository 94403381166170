document.addEventListener('DOMContentLoaded', function () {
    if (!document.getElementById('nameDeclensionForm')) {
        return;
    }

    const nameDeclensionForm = document.getElementById('nameDeclensionForm');
    const submitButton = nameDeclensionForm.querySelector('button[type="submit"]');
    const processingText = document.createElement('span');

    processingText.innerText = 'Обработка...';
    processingText.style.display = 'none';
    processingText.style.marginLeft = '20px';
    submitButton.insertAdjacentElement('afterend', processingText);

    nameDeclensionForm.addEventListener('submit', function (event) {
        submitButton.disabled = true;
        processingText.style.display = 'inline';
    });
});
