"use strict";

{
    let currentTab = 0;

    document.addEventListener("DOMContentLoaded", function (event) {
        if (!document.getElementById('late-explanation')) {
            return;
        }

        showTab(currentTab);

        const prevBtn = document.getElementById('prevBtn');
        const nextBtn = document.getElementById('nextBtn');
        const submitBtn = document.getElementById('submitBtn');
        const repeatBtn = document.getElementById('repeat');

        prevBtn.addEventListener('click', function (event) {
            nextPrev(-1);
        });
        nextBtn.addEventListener('click', function (event) {
            nextPrev(1);
        });
        submitBtn.addEventListener('click', function (event) {
            nextPrev(1);
            loadRequestFromForm();
        });
        repeatBtn.addEventListener('click', function (event) {
            document.getElementById("text-message").style.display = "none";
            document.getElementById('result').innerHTML = '';
            document.getElementById('result').style.display = 'none';
            document.getElementById("repeat").style.display = "none";
            document.getElementById("nextprevious").style.display = "block";
            document.getElementById("all-steps").style.display = "block";
            nextPrev(-4);
        });

        let reqInputs = document.querySelectorAll('input[required]');
        for (let i = 0; i < reqInputs.length; i++) {
            reqInputs[i].addEventListener("change", function() {
                reqInputs[i].classList.remove("invalid");
            });
        }
    });

    function showTab(n) {
        var x = document.getElementsByClassName("tab");
        if (x[n]) {
            x[n].style.display = "block";
        }
        if (n === 0) {
            document.getElementById("prevBtn").style.display = "none";
        } else {
            document.getElementById("prevBtn").style.display = "inline";
        }
        if (n === (x.length - 1)) {
            document.getElementById("nextBtn").style.display = "none";
            document.getElementById("submitBtn").style.display = "inline";
            // document.getElementById("nextBtn").innerHTML = "Сгенерировать";
        } else {
            document.getElementById("nextBtn").style.display = "inline";
            document.getElementById("submitBtn").style.display = "none";
            // document.getElementById("nextBtn").innerHTML = "Далее";
        }
        fixStepIndicator(n)
    }

    function nextPrev(n) {
        var x = document.getElementsByClassName("tab");
        if (n === 1 && !validateForm()) return false;
        if (x[currentTab]) {
            x[currentTab].style.display = "none";
        }
        currentTab = currentTab + n;
        if (currentTab >= x.length) {
            document.getElementById("nextprevious").style.display = "none";
            document.getElementById("all-steps").style.display = "none";
            document.getElementById("text-message").style.display = "block";
        }
        showTab(currentTab);
    }

    function validateForm() {
        let x, y, i, valid = true;
        x = document.getElementsByClassName("tab");
        y = x[currentTab].getElementsByTagName("input");
        for (i = 0; i < y.length; i++) {
            if (y[i].required && y[i].value === "") {
                y[i].className += " invalid";
                valid = false;
            }
        }
        if (valid) {
            document.getElementsByClassName("step")[currentTab].className += " finish";
        }
        return valid;
    }

    function fixStepIndicator(n) {
        let i, x = document.getElementsByClassName("step");
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" active", "");
        }
        if (x[n]) {
            x[n].className += " active";
        }
    }

    function loadRequestFromForm() {
        const form = new FormData(document.getElementById("late-explanation-form"));
        const formJSON = JSON.stringify(Object.fromEntries(form.entries()));
        fetch("/ru/generate-late-explanation", {
            method: 'POST',
            body: form,
        })
        .then(response => response.json())
        .then(function (data) {
            document.getElementById("text-message").style.display = "none";
            document.getElementById('result').innerHTML = data.result;
            document.getElementById('result').style.display = 'block';
            document.getElementById("repeat").style.display = "inline";
        })
        .catch(function (error) {
            console.log(error);
        });
    }
}
